.form {
  font-family: 'Lora', serif;
}
.form__email {
  width: 100%;
  height: 45px;
  margin-bottom: 20px;
  padding: 0 30px;
  border: 1px solid $yellowColor;
  background-color: #000;
  font-size: 17px;
  &:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 12px rgba(247,210,108,.6);
  }
}
.form__phone {
  @extend .form__email;
}
.form__text {
  @extend .form__email;
  height: 175px;
  padding: 20px 30px;
  max-width: 100%;
  max-height: 175px;
}
.form__submit {
  text-align: center;
}

.other-brands {
  margin-top: 35px;
  &__title {
    text-align: center;
    font-family: 'Lora', serif;
    font-size: 24px;
    color: $textColor;
  }
  &__list {
    list-style: none;
    margin-top: 40px;
  }
  &__item {
    width: 50%;
    float: left;
    text-align: center;
    padding: 0 15px;
    margin-bottom: 20px;
    span {
      display: inline-block;
      font-size: 15px;
      font-family: 'Lora', serif;
      color: $textColor;
      margin-top: 15px;
      line-height: 1.1;
    }
  }
  &__image {
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
@media (min-width: 768px) {
  .other-brands {
    margin-top: 65px;
    &__item {
      width: 25%;
      margin-bottom: 40px;
      span {
        margin-top: 25px;
      }
    }
  }
}

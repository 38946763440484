.result {
  list-style: none;
  @include clearfix;
  &__item {
    margin: 0 15px 15px;
    padding: 15px;
    float: left;
    width: calc(50% - 30px);
    height: 270px;
    text-align: center;
    &:hover {
      outline: 1px solid $yellowColor;
      box-shadow: 0px 0px 35px 5px rgba(247,210,108,0.2);
    }
  }
  &__image {
    display: block;
    height: 125px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  &__brand {
    display: block;
    text-align: left;
    color: $yellowColor;
    font-family: 'Lora', serif;
    font-size: 15px;
    margin-bottom: 5px;
    margin-top: 15px;
  }
  &__title {
    display: block;
    text-align: left;
    color: $textColor;
    font-size: 15px;
    line-height: 1.3;
  }
}
@media (min-width: 768px) {
  .result {
    &__item {
      width: calc(33.33334% - 30px);
      height: 240px;
    }
  }
}
@media (min-width: 992px) {
  .result {
    &__item {
      width: calc(33.33334% - 30px);
      height: 340px;
    }
    &__image {
      height: 245px;
    }
  }
}

.anchors {
  display: none;
  float: right;
  margin-right: 30px;
  &__list {
    list-style: none;
  }
  &__item {
    display: inline-block;
    margin: 0 5px;
  }
  &__link {
    font-family: 'Lora', serif;
    font-size: 12px;
    color: $textColor;
  }
}
@media (min-width: 992px) {
  .anchors {
    display: block;
  }
}

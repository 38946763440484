.triple-brands {
  margin-top: 15px;
  margin-bottom: 35px;
  &--frontpage {
    margin-top: 0;
    margin-bottom: 0;
  }
  &__name {
    margin-bottom: 35px;
  }
  &__list {
    list-style: none;
    @include clearfix;
  }
  &__item {
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    &--front {
      margin-bottom: 0;
    }
  }
  &__logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 4;
    &--stain {
      background-image: url(../../assets/img/sl-logo.svg);
      background-size: 70% auto;
    }
    &--lyng {
      background-image: url(../../assets/img/l-logo.png);
      background-size: auto 30%;
    }
    &--grad {
      background-image: url(../../assets/img/g-logo.png);
      background-size: auto 30%;
    }
  }
  &__image {
    margin-left: -15px;
    margin-right: -15px;
    display: block;
    text-align: center;
    height: 320px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    span {
      font-size: 24px;
      //text-transform: uppercase;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1.1;
      z-index: 2;
    }
    &:hover {
      .triple-brands__background {
        transition: all ease 0.5s;
        transform: scale3d(1.1, 1.1, 1.1);
        &:before {
          background-color: rgba(0, 0, 0, .6);
          transition: all ease 0.5s;
        }
      }
    }
  }
  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: all ease 0.5s;
    z-index: 1;
    &--stain {
      background-image: url(../../assets/img/stainway-lyngdorf-banner.jpg);
    }
    &--lyng {
      background-image: url(../../assets/img/lyngdorf-banner.jpg);
    }
    &--grad {
      background-image: url(../../assets/img/gradient-banner.jpg);
    }
    &:before {
      content: "";
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .3);
      transition: all ease 0.5s;
    }
  }
  &__text {
    margin-top: 35px;
    font-size: 13px;
    color: $textColor;
    line-height: 1.5;
  }
  &__link {
    margin-top: 30px;
  }
}
@media (min-width: 768px) {
  .triple-brands {
    &__item {
      width: 33.33334%;
      float: left;
      margin-bottom: 0;
    }
    &__image {
      margin-left: 0;
      margin-right: 0;
    }
    &__text {
      padding: 0 15px;
    }
  }
}
@media (min-width: 992px) {
  .triple-brands {
    margin-top: 50px;
    margin-bottom: 65px;
    &--frontpage {
      margin-top: 0;
      margin-bottom: 0;
    }
    &__text {
      padding: 0 30px;
      font-size: 15px;
    }
  }
}

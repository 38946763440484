.fa-instagram:before {
  content: "\f16d";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-youtube-play:before {
  content: "\f16a";
}

input[type="checkbox"] {
    display:none;
}
input[type="checkbox"] + label {
  font-size: 13px;
  color: $textColor;
  cursor: pointer;
  &:before {
    content: "";
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 0 10px 0 0;
    background-image: url(../../assets/img/checkbox.png);
    background-repeat: no-repeat;
    background-size: auto 10px;
    background-position: -11px 0px;
  }
}
input[type="checkbox"]:checked + label {
  color: $yellowColor;
  &:before {
    background-position: 0px 0px;
  }
}
.scroll-stop {
  overflow: hidden;
  height: 100%;
}
@media (min-width: 992px) {
  input[type="checkbox"] + label {
    font-size: 15px;
    &:before {
      height: 13px;
      width: 13px;
      background-size: auto 13px;
      background-position: -15px 0px;
    }
  }  
}

.map {
  margin-left: -15px;
  margin-right: -15px;
  height: 465px;
}
@media (min-width: 768px) {
  .map {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .map {
    height: 575px;
  }
}

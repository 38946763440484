.triple-description {
  margin-top: 35px;
  margin-bottom: 65px;
}
.triple-description__list {
  list-style: none;
  @include clearfix;
}
.triple-description__item {
  float: left;
  width: 33.33334%;
  text-align: center;
}
.triple-description__text {
  font-size: 15px;
  color: $textColor;
  line-height: 1.5;
  padding: 0 30px;
}
.triple-description__button {
  margin-top: 30px;
}

.technologies {
  margin-top: 30px;
  &__name {
    margin-bottom: 15px;
  }
  &__list {
    list-style: none;
  }
  &__item {
    padding: 25px 0;
    position: relative;
    overflow: hidden;
  }
  &__title {
    font-size: 15px;
    font-family: 'Lora', serif;
    color: $yellowColor;
    margin-bottom: 10px;
    line-height: 1.3;
  }
  &__caption {
    font-size: 13px;
    line-height: 1.5;
    max-height: calc(250px + 3em);
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &__details {
    margin-top: 20px;
    visibility: visible;
  }
  &__video {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    transition: all 0.5s;
    &--active {
      opacity: 1;
      visibility: visible;
      transition: all 0.5s;
    }
  }
}
@media (min-width: 667px) {
  .technologies {
    &__caption {
      max-height: calc(410px + 3em);
    }
  }
}
@media (min-width: 768px) {
  .technologies {
    &__caption {
      max-height: calc(460px + 3em);
    }
  }
}
@media (min-width: 992px) {
  .technologies {
    margin: 65px 160px 0;
    &__title {
      font-size: 20px;
    }
    &__caption {
      font-size: 17px;
      max-height: calc(410px + 3em);
    }
  }
}
@media (min-width: 1200px) {
  .technologies {
    &__caption {
      max-height: calc(520px + 3em);
    }
  }
}
@media (min-width: 1376px) {
  .technologies {
    &__caption {
      max-height: calc(565px + 3em);
    }
  }
}

.featured-products {
  margin-top: 30px;
  &__name {
    margin-bottom: 20px;
  }
  &__list {

  }
  &__entry {
    padding: 20px;
    margin: 10px;
    &:hover {
      outline: 1px solid $yellowColor;
      box-shadow: 0px 0px 35px 5px rgba(247,210,108,0.2);
    }
  }
  &__item {
    display: block;
  }
  &__image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 125px;
  }
  &__brand {
    text-align: left;
    color: $yellowColor;
    font-family: 'Lora', serif;
    font-size: 11px;
    margin-bottom: 5px;
    margin-top: 15px;
  }
  &__title {
    text-align: left;
    color: $textColor;
    font-size: 11px;
    line-height: 1.3;
  }
  &__all {
    margin-top: 30px;
    text-align: center;
  }
}
@media (min-width: 992px) {
  .featured-products {
    margin-top: 50px;
    &__entry {
      margin: 20px;
    }
    &__image {
      height: 200px;
    }
    &__brand {
      font-size: 15px;
    }
    &__title {
      font-size: 15px;
    }
  }
}

.contacts {
  margin: -150px 0 0;
  position: relative;
  z-index: 2;
  &__tabs {
    @include clearfix;
    list-style: none;
  }
  &__tab {
    float: left;  
    text-align: center;
    font-size: 17px;
    color: $textColor;
    font-family: 'Lora', serif;
    padding: 20px 0;
    background-color: rgba(0, 0, 0, .9);
    cursor: pointer;
    &--active {
      background-color: rgba(18, 18, 18, .9);
      color: $yellowColor;
      cursor: default;
    }
  }
  &__info {
    display: none;
    background-color: rgba(18, 18, 18, .9);
    padding: 30px 15px 50px;
    &--active {
      display: block;
    }
  }
  &__address {
    font-size: 17px;
    color: $textColor;
    font-family: 'Lora', serif;
    margin-bottom: 30px;
  }
  &__list {
    list-style: none;
  }
  &__item {
    margin-bottom: 15px;
    position: relative;
    padding-left: 25px;
    font-family: 'Lora', serif;
    &:last-child {
      margin-bottom: 0;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &--schedule {
      &:after {
        background-image: url(../../assets/img/clock.png);
        width: 13px;
        height: 13px;
        margin-top: -6.5px;
      }
    }
    &--phone {
      &:after {
        background-image: url(../../assets/img/phone.png);
        width: 14px;
        height: 14px;
        margin-top: -7px;
      }
    }
    &--mail {
      &:after {
        background-image: url(../../assets/img/mail.png);
        width: 14px;
        height: 12px;
        margin-top: -6px;
      }
    }
  }
  &__form {
    background-color: rgba(18, 18, 18, .9);
    padding: 0 15px 30px;
  }
}
@media (min-width: 768px) {
  .contacts {
    margin: -150px 80px 0;
    &__info {
      padding: 30px 35px 50px;
    }
    &__form {
      padding: 0 35px 30px;
    }
    &__address {
      font-size: 22px;
    }
  }
}
@media (min-width: 992px) {
  .contacts {
    margin: -225px 195px 0;
    &__address {
      font-size: 24px;
    }
  }
}

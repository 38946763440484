.characteristics {
  @include clearfix;
  padding: 20px 0;
  position: relative;
  &__image {
    height: 350px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  &__info {

  }
  &__title {
    font-size: 17px;
    font-family: 'Lora', serif;
    margin-bottom: 20px;
  }
  &__table {
    width: calc(100% - 30px);
    margin: 10px 15px;
    tr {
      border-bottom: 1px solid $brownColor;
      &:last-child {
        border-bottom: 0;
      }
      td {
        width: 50%;
        font-size: 15px;
        padding: 7.5px 0;
        line-height: 1.3;
      }
    }
  }
}

@media (min-width: 992px) {
  .characteristics {
    margin-top: 65px;
    &__image {
      //float: left;
      //width: 50%;
      height: 750px;
    }
    &__info {
      //float: right;
      //width: 50%;
      //margin: 0 auto;
    }
    &__title {
      font-size: 24px;
    }
  }
}

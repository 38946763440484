.slider {
  margin-left: -15px;
  margin-right: -15px;
  &__image {
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 320px;
  }
}
@media (min-width: 768px) {
  .slider {
    margin-left: 0;
    margin-right: 0;
    &__image {
      height: 405px;
    }
  }
}
@media (min-width: 992px) {
  .slider {
    &__image {
      height: 529px;
    }
  }
}
@media (min-width: 1200px) {
  .slider {
    &__image {
      height: 641px;
    }
  }
}
@media (min-width: 1364px) {
  .slider {
    &__image {
      height: 750px;
    }
  }
}

.article {
  position: relative;
  z-index: 2;
  &__content {
    padding: 15px;
  }
  &__title {
    font-size: 18px;
    color: $yellowColor;
    font-family: 'Lora', serif;
    line-height: 1.1;
  }
  &__subtitle {
    font-size: 18px;
    color: $textColor;
    font-family: 'Lora', serif;
    line-height: 1.1;
  }
  &__text {
    margin-top: 30px;
    font-size: 13px;
    line-height: 1.5;
    p {
      margin: 0 0 30px 0;
    }
    blockquote {
      p {
        font-size: 15px;
        color: $yellowColor;
        position: relative;
        padding: 30px 0;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #303030;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #303030;
        }
      }
    }
  }
  &__info {
    @include clearfix;
    padding: 15px 0 45px;
  }
  &__social {
    float: left;
  }
  &__date {
    float: right;
    font-size: 13px;
    color: #666;
  }
  &__back {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .article {
    &__title {
      font-size: 28px;
    }
    &__subtitle {
      font-size: 28px;
    }
    &__text {
      font-size: 15px;
      blockquote {
        p {
          font-size: 17px;
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .article {
    margin: 0 65px;
    &__content {
      padding: 50px 95px;
    }
    &__title {
      font-size: 36px;
    }
    &__subtitle {
      font-size: 36px;
    }
    &__text {
      margin-top: 40px;
      font-size: 17px;
      blockquote {
        p {
          font-size: 19px;
        }
      }
    }
    &__info {
      padding: 15px 95px 45px;
    }
    &__back {
      padding-left: 95px;
    }
  }
}

.header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  background-color: $grayColor;
  height: $headerHeight;
  &__logo {
    position: absolute;
    &--top {
      top: 20px;
      left: 50%;
      transform: translate(-50%, 0);
      text-align: center;
      img {
        width: 184px;
        height: 71px;
      }
    }
    &--bottom {
      float: left;
      margin: 15px 0;
      img {
        width: 132px;
        height: 48px;
      }
    }
  }
  &__menu {
    position: absolute;
    width: 100%;
    &--top {
      //margin-bottom: 30px;
      top: 125px;
    }
    &--bottom {
      float: left;
      margin-top: 33px;
      margin-left: 50px;
    }
  }
  &__lang-chooser {
    position: absolute;
    top: 25px;
    right: 60px;
  }
  &__search {
    position: absolute;
    right: 25px;
    top: 120px;
    width: 22px;
    height: 22px;
    background-image: url(../../assets/img/search-white.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    &--active {
      background-image: url(../../assets/img/search.png);
    }
  }
}
@media (min-width: 1200px) {
  .header {
    display: block;
  }
}

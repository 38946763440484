.option {
  @include clearfix;
  &__description {
    display: table-cell;
    vertical-align: middle;
  }
  &__info {
    display: table;
    min-height: 500px;
  }
  &__title {
    font-size: 17px;
    font-family: 'Lora', serif;
    margin-bottom: 20px;
  }
  &__list {
    list-style: none;
    margin: 15px 0;
    padding: 0 15px;
  }
  &__item {
    border-bottom: 1px solid $brownColor;
    padding-bottom: 7.5px;
    margin-bottom: 7.5px;
    font-size: 13px;
    a {
      &:hover {
        color: $yellowColor;
      }
    }
    p {
      display: inline;
    }
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
    &--title {
      color: $yellowColor;
    }
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 320px;
  }
}
@media (min-width: 992px) {
  .option {    
    &__info {
      float: left;
      min-width: 230px;
      max-width: 50%;
      padding: 30px;
    }
    &__image {
      position: static;
      float: right;
      height: 500px;
      width: 50%;
    }
    &__title {
      font-size: 24px;
    }
    &__item {
      font-size: 15px;
    }
  }
}

.news {
  &--query {
    padding-top: 20px;
  }
  &--about {
    margin-top: 85px;
  }
  &__name {
    margin-bottom: 30px;
  }
  &__list {
    list-style: none;
    @include clearfix;
  }
  &__item {
    @include clearfix;
    overflow: hidden;
    height: 400px;
    margin-bottom: 30px;
  }
  &__image {
    display: block;
    height: 260px;
    text-align: center;
    overflow: hidden;
  }
  &__background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    transition: all ease 0.5s;
    &:hover {
      transition: all ease 0.5s;
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }
  &__article {
    padding: 15px 0 0;
  }
  &__title {
    font-family: 'Lora', serif;
    font-size: 17px;
    color: $yellowColor;
    line-height: 1.1;
  }
  &__text {
    margin: 15px 0;
    font-size: 13px;
    color: $textColor;
    line-height: 1.5;
    max-height: 4.5em;
    overflow: hidden;
    position: relative;
    p {
      margin-bottom: 0;
    }
    // &:after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 25px;
    //   background: linear-gradient(to top, #000000 0%,transparent 100%);
    // }
  }
  &__all {
    margin-top: 30px;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .news {
    &__item {
      float: left;
      height: 450px;
      width: calc(50% - 5px);
      margin-right: 10px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    &__title {
      font-size: 20px;
    }
    &__text {
      font-size: 15px;
    }
  }
}
@media (min-width: 992px) {
  .news {
    &--query {
      padding-top: 0;
    }
    &__title {
      font-size: 24px;
    }
  }
}

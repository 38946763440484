.series {
  margin-top: 30px;
  &__block {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__name {
    margin-bottom: 25px;
  }
  &__search {
    font-size: 15px;
    font-family: 'Lora', serif;
    margin-bottom: 50px;
  }
  &__list {
    list-style: none;
    display: flex;
	  flex-wrap: wrap;
  }
  &__item {
    width: 100%;
    margin-bottom: 30px;
  }
  &__image {
    display: block;
    height: 205px;
    overflow: hidden;
  }
  &__background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    transition: all ease 0.5s;
    &:hover {
      transition: all ease 0.5s;
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }
  &__title {
    font-family: 'Lora', serif;
    margin-top: 20px;
    padding-right: 20px;
    line-height: 1.3;
    font-size: 18px;
  }
  &__details {
    margin-top: 10px;
  }
}
@media (min-width: 768px) {
  .series {
    &__item {
      width: calc(50% - 9px);
      margin-right: 12px;
      &:nth-child(2n) {
        margin-right: 0px;
      }
    }
  }
}
@media (min-width: 992px) {
  .series {
    margin-top: 65px;
    &__block {
      margin-bottom: 65px;
    }
    &__item {
      width: calc(33.333% - 9px);
      &:nth-child(2n) {
        margin-right: 12px;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
@media (min-width: 1200px) {
  .series {
    &__item {
      width: calc(25% - 9px);
      &:nth-child(2n) {
        margin-right: 12px;
      }
      &:nth-child(3n) {
        margin-right: 12px;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}

.arrow {
  display: inline-block;
  background-image: url(../../assets/img/arrow-down.png);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: 0;
  &:disabled {
    opacity: .3;
  }
}
.arrow--down {
  width: 14px;
  height: 8px;
  transform: rotate(0deg);
  transition: transform ease 0.2s;
}
.arrow--left {
  height: 8px;
  width: 14px;
  transform: rotate(90deg);
  transition: transform ease 0.2s;
}
.arrow--right {
  height: 8px;
  width: 14px;
  transform: rotate(-90deg);
  transition: transform ease 0.2s;
}

.spotlight {
  position: fixed;
  left: 0;
  top: 50px;
  background-color: #353535;
  height: 55px;
  width: 100%;
  z-index: 11;
  line-height: 55px;
  visibility: hidden;;
  opacity: 0;
  transition: all .3s ease;
  &--active {
    visibility: visible;
    opacity: 1;
    transition: all .3s ease;
  }
  &__input {
    height: 55px;
    width: 100%;
    outline: 0;
    background-color: #353535;
    border: 0;
    font-size: 24px;
    font-family: 'Lora', serif;
    color: $yellowColor !important;
  }
}
@media (min-width: 1200px) {
  .spotlight {
    top: $headerHeight;
  }
}

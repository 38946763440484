.name {
  font-size: 18px;
  color: $yellowColor;
  font-family: 'Lora', serif;
  line-height: 1.3;
  margin-top: 30px;
  &__title {
    color: $textColor;
  }
}
@media (min-width: 768px) {
  .name {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .name {
    margin-top: 50px;
    font-size: 36px;
  }
}

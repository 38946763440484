.breadcrumbs {
  float: left;
  &__list {
    list-style: none;
    @include clearfix;
  }
  &__item {
    float: left;
    position: relative;
    margin-right: 15px;
    &:after {
      content: "";
      position: absolute;
      width: 4px;
      height: 7px;
      right: -10px;
      top: 50%;
      margin-top: -2px;
      background-image: url(../../assets/img/breadcrumb-arrow.png);
      background-repeat: no-repeat;
      background-size: contain;
    }
    &:last-child {
      margin-right: 0;
      &:after {
        content: none;
      }
    }
  }
  &__link {
    font-family: 'Lora', serif;
    font-size: 12px;
  }
}
@media (min-width: 768px) {
  .breadcrumbs {
    &__link {
      // font-size: 13px;
    }
  }
}
@media (min-width: 992px) {
  .breadcrumbs {
    margin-left: 30px;
    &__item {
      margin-right: 20px;

    }
    &__link {
      // font-size: 15px;
    }
  }
}

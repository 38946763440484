.wrapper--news {
  padding-top: 36px;
}
.wrapper--production {
  padding-top: 36px;
}
@media (min-width: 1200px) {
  .wrapper--production {
    padding-top: 0;
  }
}

.filter {
  display: none;
  margin-top: 30px;
  float: left;
  width: 220px;
  &__title {
    font-size: 13px;
    font-family: 'Lora', serif;
    color: #666;
    padding-bottom: 20px;
    border-bottom: 1px solid #121212;
  }
}
.filter-checker {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #121212;
  &__title {
    display: inline-block;
    font-size: 13px;
    font-family: 'Lora', serif;
    color: $textColor;
    cursor: pointer;
    span {
      margin-right: 5px;
    }
  }
  &__list {
    margin-top: 20px;
    list-style: none;
    display: none;
    overflow: hidden;
  }
  &__item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 1200px) {
  .filter {
    display: block;
    position: fixed;    
  }
  .filter-checker {
    &__title {
      font-size: 15px;
      span {
        margin-right: 5px;
      }
    }
  }
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  background-color: #000;
  color: $textColor;
  line-height: 1;  
  -webkit-font-smoothing: antialiased;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
ol, ul, li , blockquote {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: $textColor;
}
p {
  padding: 0;
  margin: 0 0 15px 0;
}
@font-face {
  font-family: 'FontAwesome';
  src: url('../../assets/fonts/awesome/fontawesome-webfont.eot?v=4.4.0');
  src: url('../../assets/fonts/awesome/fontawesome-webfont.eot?#iefix&v=4.4.0') format('embedded-opentype'),
  url('../../assets/fonts/awesome/fontawesome-webfont.woff2?v=4.4.0') format('woff2'),
  url('../../assets/fonts/awesome/fontawesome-webfont.woff?v=4.4.0') format('woff'),
  url('../../assets/fonts/awesome/fontawesome-webfont.ttf?v=4.4.0') format('truetype'),
  url('../../assets/fonts/awesome/fontawesome-webfont.svg?v=4.4.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}
.wp-video {
  margin: 0 auto;
  width: 900px !important;
  video {
    width: 100% !important;
    height: auto !important;
    background-size: 100% 100%;
  }
}
/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.wp-video-shortcode {
	max-width: 100%;
}

.wp-audio-shortcode {
	max-width: 100%;
}

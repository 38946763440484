.review {
  margin-top: 10px;
  @include clearfix;
  position: relative;
  overflow: hidden;
  &__description {
    display: table;
  }
  &__content {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    padding: 20px 0px;
  }
  &__title {
    font-size: 18px;
    color: $yellowColor;
    font-family: 'Lora', serif;
    margin-bottom: 20px;
  }
  &__text {
    font-size: 13px;
    color: $textColor;
    line-height: 1.5;
  }
  &__image {
    min-height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
@media (min-width: 768px) {
  .review {
    margin-top: 30px;
    &__title {
      font-size: 28px;
    }
    &__text {
      font-size: 15px;
    }
    &__image {
      min-height: 350px;
    }
  }
}
@media (min-width: 992px) {
  .review {
    padding: 0;
    margin-top: 65px;
    &__description {
      //width: 50%;
      //min-height: 750px;
      &--left {
        //float: left;
        //padding-right: 30px;
      }
      &--right {
        //float: right;
        //padding-left: 30px;
      }
    }
    &__title {
      font-size: 36px;
    }
    &__text {
      font-size: 17px;
    }
    &__image {
      min-height: 750px;
      //width: 50%;
      &--left {
        //float: left;
      }
      &--right {
        //float: right;
      }
    }
  }
}

.link {
  display: inline-block;
  position: relative;
  color: #999;
  cursor: pointer;
  font-family: 'Lora', serif;
  &:after {
    content: ">";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate3d(15px, -50%, 0);
    transition: transform ease 0.2s;
  }
  &:hover {
    color: $yellowColor;
    &:after {
      transform: translate3d(20px, -50%, 0);
      transition: transform ease 0.2s;
    }
  }
}

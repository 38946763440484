.banner {
  margin-left: -15px;
  margin-right: -15px;
  height: 320px;
  overflow: hidden;
  position: relative;
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 150px;
      background: linear-gradient(to top, rgba(0, 0, 0, .9) 0%,rgba(255, 255, 255, 0) 100%);
    }
  }
  &__title {
    position: absolute;
    left: 15px;
    bottom: 30px;
    font-size: 18px;
    color: $yellowColor;
    font-family: 'Lora', serif;
    z-index: 4;
    line-height: 1.3;
  }
  &__subtitle {
    color: $textColor;
  }
}
@media (min-width: 768px) {
  .banner {
    height: 422px;
    margin-left: 0;
    margin-right: 0;
    &__image {
        &:after {
          height: 300px;
        }
    }
    &__title {
      left: 30px;
      font-size: 28px;
    }
  }
}
@media (min-width: 992px) {
  .banner {
    height: 529px;
    &__title {
      font-size: 36px;
    }
  }
}
@media (min-width: 1200px) {
  .banner {
    height: 641px;
  }
}
@media (min-width: 1364px) {
  .banner {
    height: 750px;
  }
}

.lang-switcher {
  width: 40px;
  position: absolute;
  text-align: center;
  z-index: 5;
  font-family: 'Lora', serif;
  &__current {
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      right: 3px;
      top: 50%;
      margin-top: -2px;
      border: 3px solid transparent;
      border-top: 3px solid #fff;
    }
  }
  &__list {
    margin-top: 5px;
    list-style: none;
    visibility: hidden;
    opacity: 0;
    border: 1px solid #d9d9d9;
    transform: translate3d(0, 10px, 0);
    transition: all ease 0.3s;
    background-color: #000;
    padding: 2px;
    &--active {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0);
      transition: all ease 0.3s;
    }
  }
  &__item {
    padding: 2px 0;
  }
  &__link {
    font-size: 16px;
    text-transform: uppercase;
  }
}
@media (min-width: 1200px) {
  .lang-switcher {
    &:hover {
      .lang-switcher__list {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 0, 0);
        transition: all ease 0.3s;
      }
    }
    &__current {
      font-size: 14px;
    }
    &__link {
      font-size: 14px;
    }
  }
}

.title {
  font-size: 18px;
  font-family: 'Lora', serif;
}
@media (min-width: 768px) {
  .title {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .title {
    font-size: 36px;
  }
}

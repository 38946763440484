.pagination {
  line-height: 14px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, 0);
  width: 100%;
  &__text {
    font-size: 13px;
    font-family: 'Lora', serif;
    color: $textColor;
    margin-left: 5px;
  }
  &__current {
    font-size: 13px;
    text-transform: uppercase;
    color: $yellowColor;
    margin-left: 5px;
  }
  &__all {
    font-size: 13px;
    text-transform: uppercase;
    color: $yellowColor;
    margin-right: 10px;
    margin-left: 5px;
  }
}
@media (min-width: 992px) {
  .pagination {
    right: 0;
    transform: translate(0, 0);
    width: auto;
  }
}

.sort {
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  @include clearfix;
  height: 1em;
}
.show-count {
  display: none;
  &__title {
    color: $textColor;
    font-size: 13px;
    font-family: 'Lora', serif;
    margin-right: 10px;
  }
  &__list {
    display: inline-block;
    list-style: none;
  }
  &__item {
    display: inline-block;
    font-size: 13px;
    color: $textColor;
    cursor: pointer;
    margin: 0 4px;
    &--active {
      color: $yellowColor;
    }
  }
}
@media (min-width: 992px) {
  .show-count {
    display: block;
  }
}

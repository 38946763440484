.m-header {
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  background-color: $grayColor;
  &__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -38px;
    margin-top: -15px;
    img {
      width: 76px;
      height: 30px;
    }
  }
  &__lang-chooser {
    position: absolute;
    top: 20px;
    left: 15px;
  }
  &__search {
    position: absolute;
    right: 60px;
    top: 15px;
    width: 18px;
    height: 18px;
    background-image: url(../../assets/img/search-white.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    &--active {
      background-image: url(../../assets/img/search.png);
    }
  }
}
@media (min-width: 1200px) {
  .m-header {
    display: none;
  }
}

.awards {
  margin-top: 65px;
  &__name {
    margin-bottom: 30px;
  }
  &__list {
    list-style: none;
    position: relative;
    margin-top: 115px;
    &:before {
      content: "";
      position: absolute;
      top: -85px;
      left: 50%;
      background-image: url(../../assets/img/awards.png);
      background-size: contain;
      width: 58px;
      height: 55px;
      margin-left: -29px;
    }
  }
  &__item {
    padding-bottom: 30px;
  }
  &__title {
    font-size: 15px;
    font-family: 'Lora', serif;
    color: $yellowColor;
    text-align: center;
    margin-bottom: 15px;
  }
  &__text {
    font-size: 13px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .awards {
    &__title {
      font-size: 17px;
    }
    &__text {
      font-size: 15px;
    }
  }
}

.sub-header {
  background-color: rgba(18, 18, 18, .9);
  text-align: center;
  padding: 10px 0;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 10;
  &--top {
    top: 50px;
  }
  &--bottom {
    top: $headerHeightSmall;
  }
  &__list {
    display: none;
    list-style: none;
  }
  &__item {
    display: inline-block;
    margin: 0 10px;
  }
  &__date {
    font-size: 13px;
    color: $textColor;
    cursor: pointer;
    &--active {
      color: $yellowColor;
    }
  }
  &__link {
    font-family: 'Lora', serif;
    font-size: 15px;
    color: $textColor;
    &--active {
      color: $yellowColor;
    }
  }
  &__filter {

  }
}
@media (min-width: 1200px) {
  .sub-header {
    &--mobile {
      display: none;
    }
    &--top {
      top: $headerHeight;
    }
    &__list {
      display: block;
    }
    &__filter {
      display: none;
    }
    &__date {
      font-size: 15px;
    }
  }
}

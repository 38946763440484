.article-nav {
  @include clearfix;
  &__item {
    width: 50%;
    padding: 25px 30px;
    background-color: $grayColor;
    &:first-child {
      border-right: 1px solid #000;
    }
    &:last-child {
      border-left: 1px solid #000;
    }
    &--prev {
      float: left;
    }
    &--next {
      float: right;
    }
  }
  &__direction {
    font-size: 13px;
    color: $textColor;
    font-family: 'Lora', serif;
    line-height: 1.3;
  }
  &__title {
    display: none;
    font-size: 24px;
    font-family: 'Lora', serif;
    color: $yellowColor;
    line-height: 1.1;
    margin-bottom: 10px;
    a {
      color: $yellowColor;
    }
  }
  &__date {
    display: none;
    font-size: 13px;
    font-family: 'Lora', serif;
    color: #666;
    margin-bottom: 25px;
  }
  &__text {
    display: none;
    font-size: 15px;
    line-height: 1.5;
  }
}
@media (min-width: 992px) {
  .article-nav {
    &__direction {
      margin-bottom: 25px;
    }
    &__title {
      display: block;
    }
    &__date {
      display: block;
    }
    &__text {
      display: block;
    }
  }
}

.variation {
  margin-top: 30px;
  &__title {
    margin-bottom: 30px;
    color: $yellowColor;
  }
  &__info {

  }
  &__list {
    list-style: none;
  }
  &__image {
    min-height: 200px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  &__item {
    margin: 40px 0;
  }
  &__name {
    font-size: 17px;
    font-family: 'Lora', serif;
    margin-bottom: 5px;
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .variation {
    &__image {
      min-height: 350px;
    }
  }
}
@media (min-width: 992px) {
  .variation {
    margin-top: 65px;
    &__info {
      float: left;
      width: 360px;
      min-height: 350px;
      display: table;
    }
    &__list {
      display: table-cell;
      vertical-align: middle;
    }
    &__name {
      font-size: 24px;
    }
    &__image {
      float: right;
      width: calc(100% - 360px);
      background-position: right center;
      background-size: auto 100%;
    }
  }
}

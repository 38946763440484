.m-filter {
  @include clearfix;
  position: relative;
  &__top {
    @include clearfix;
  }
  &__name {
    float: left;
    text-transform: uppercase;
    margin-left: 15px;
    font-size: 13px;
  }
  &__content {
    max-height: 400px;
    overflow: scroll;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 23px;
    left: 0;
    width: 100%;
    background-color: rgba(18, 18, 18, .9);
    text-align: left;
    padding: 20px 30px;
    transition: all .3s ease;
    transform: translate3d(0, 20px, 0);
    &--active {
      transition: all .3s ease;
      transform: translate3d(0, 0, 0);
      visibility: visible;
      opacity: 1;
    }
  }
  &__arrow {
    float: right;
    margin-right: 15px;
    display: inline-block;
    background-image: url(../../assets/img/arrow-down.png);
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    outline: 0;
    width: 14px;
    height: 8px;
    transition: all .3s ease;
    &--active {
      transition: all .3s ease;
      transform: rotate(180deg);
    }
  }
  &__list {
    list-style: none;
    margin-left: -15px;
    margin-right: -15px;
  }
  &__item {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #303030;
  }
  &__link {
    font-size: 13px;
    text-transform: uppercase;
  }
}

.divider {
  background-color: $yellowColor;
  width: 75%;
  height: 1px;
  margin: 0 auto;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: -9px;
    top: 50%;
    width: 9px;
    height: 9px;
    margin-top: -4.5px;
    background-image: url(../../assets/img/divider.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
  &:after {
    content: "";
    position: absolute;
    right: -9px;
    top: 50%;
    width: 9px;
    height: 9px;
    margin-top: -4.5px;
    background-image: url(../../assets/img/divider.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
  &--fluid {
    width: calc(100% - 18px);
    margin: 0 auto;
  }
}
@media (min-width: 992px) {
  .divider {
    width: 350px;
    &--fluid {
      width: calc(100% - 18px);
      margin: 0 auto;
    }
  }
}

.footer {
  margin-top: 45px;
  padding-top: 20px;
  @include clearfix;
  background-color: $grayColor;
  &__top {
    @include clearfix;
  }
  &__bottom {
    @include clearfix;
    border-top: 1px solid #303030;
    padding: 15px 0;
  }
  &__copyright {
    float: right;
    font-size: 13px;
  }
}
.footer-list {
  list-style: none;
  &__item {
    border-top: 1px solid #303030;
    padding-top: 10px;
    float: left;
    width: 50%;
  }
  &__title {
    display: inline-block;
    font-size: 13px;
    font-family: 'Lora', serif;
    color: $brownColor;
    margin-bottom: 20px;
  }
}
.footer-sublist {
  list-style: none;
  &__item {
    font-size: 13px;
    margin-bottom: 10px;
    line-height: 1.5;
  }
}
@media (min-width: 992px) {
  .footer {
    padding-top: 30px;
    &__logo {
      float: left;
      margin-bottom: 0;
      img {
        width: 132px;
        height: 48px;
      }
    }
    &__bottom {
      margin-top: 40px;
    }
  }
  .footer-list {
    &__item {
      float: left;
      width: 25%;
      border-top: 0;
      padding-top: 0;
    }
  }
}

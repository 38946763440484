.m-menu {
  position: relative;
  &__icon {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 20px;
    height: 17px;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $textColor;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 0px;
        transform-origin: left center;
      }
      &:nth-child(2) {
        top: 7px;
        transform-origin: left center;
      }
      &:nth-child(3) {
        top: 14px;
        transform-origin: left center;
      }
    }
    &--active {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: -2px;
          left: 2px;
        }
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 12px;
          left: 2px;
        }
      }
    }
  }
  &__list {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    list-style: none;
    top: 50px;
    right: 0;
    width: 250px;
    background-color: $grayColor;
    padding: 20px 30px;
    transition: all .3s ease;
    transform: translate3d(0, 10px, 0);
    &--active {
      visibility: visible;
      opacity: 1;
      transition: all .3s ease;
      transform: translate3d(0, 0, 0);
    }
    .menu-item {
      font-family: 'Lora', serif;
      margin: 20px 0;
      &--active {
        a {
          color: $yellowColor;
        }        
      }
      .sub-menu {
        display: none;
      }
    }
  }
}

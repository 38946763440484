.query {
  @include clearfix;
  @media (min-width: 1200px) {
    float: right;
    width: calc(100% - 220px);
    &--portfolio {
      padding-left: 50px;
    }
  }
}

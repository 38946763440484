.social {
  &__title {
    font-size: 13px;
    //text-transform: uppercase;
    color: #666;
    margin-bottom: 15px;
  }
}
@mixin transition($transition:0.1s all) {  
  transition: #{$transition};
}

[class^="icon-"], [class*=" icon-"] {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/*================ Social share buttons ================*/
$shareButtonHeight: 25px;
$shareButtonCleanHeight: 30px;
$shareCountBg: #fff;
$shareBorderColor: #ececec;

.social-sharing {

  a {
    display: inline-block;
    color: $textColor;
    margin: 0 10px 10px 0;
    height: $shareButtonHeight;
    line-height: $shareButtonHeight;
    text-decoration: none;
    font-weight: normal;
    border: 1px solid $textColor;
    &:hover {
      color: #fff;
    }
  }

  span {
    display: inline-block;
    vertical-align: top;
    height: $shareButtonHeight;
    line-height: $shareButtonHeight;
    font-size: 12px;
  }

  .icon {
    padding: 0 5px 0 10px;

    &:before {
      line-height: $shareButtonHeight;
    }
  }
}

.share-title {
  font-weight: 900;
  font-size: 12px;
  padding-right: 10px;

  .is-large & {
    padding-right: 16px;
  }
}

.share-facebook {
  background-color: #121212;
}

.share-twitter {
  background-color: #121212;
}
.share-count {
  position: relative;
  background-color: $shareCountBg;
  padding: 0 8px;
  margin-right: -2px;
  font-size: 14px;
  line-height: $shareButtonHeight - 2;
  color: #333;
  border-radius: 0 2px 2px 0;
  border: 1px solid $shareBorderColor;
  opacity: 0;
  @include transition(opacity 0.2s ease-in);

  &.is-loaded {
    opacity: 1;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid;
  }

  &:before {
    left: -6px;
    border-right-color: $shareBorderColor;
  }

  &:after {
    left: -5px;
    border-right-color: $shareCountBg;
  }

  a:hover & {
    background-color: darken($shareCountBg, 4%);

    &:after {
      border-right-color: darken($shareCountBg, 4%);
    }
  }
  .is-large & {
    font-size: 18px;
    padding: 0 14px;
  }
}

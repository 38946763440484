.button {
  display: inline-block;
  font-size: 15px;
  text-transform: uppercase;
  border: 1px solid $yellowColor;
  color: $yellowColor;
  background-color: transparent;
  padding: 10px 20px 7px;
  border-radius: 0;
  line-height: 1;
  letter-spacing: 1px;
  outline: 0;
  cursor: pointer;
  &:hover {
    background-color: $yellowColor;
    color: #000;
    box-shadow: 0px 0px 15px 5px rgba(247,210,108,0.2);
  }
}

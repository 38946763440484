.menu {
  list-style: none;
  text-align: center;
  li {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #303030;
    font-size: 15px;
  }
  & > .menu-item {
    position: relative;
    a {
      font-family: 'Lora', serif;
      &:hover {
        color: $yellowColor;
      }
    }
    &--active {
      & > a {
        color: $yellowColor;
      }
    }
    &:hover {
      & > .sub-menu {
        visibility: visible;
        transition: visibility ease 0.1s;
      }
    }
    & > .sub-menu {
      &:before {
        content: "";
        position: absolute;
        top: -15px;
        left: 20px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
	      border-right: 15px solid transparent;
	      border-bottom: 15px solid #222;
      }
    }
    .sub-menu {
      border-right: 1px solid #000;
      border-left: 1px solid #000;
      border-bottom: 1px solid #000;
      visibility: hidden;
      transition: visibility ease 0.1s;
      position: absolute;
      top: 38px;
      left: 0;
      width: 250px;
      background-color: #222;
      text-align: left;
      padding: 25px 0;
      .menu-item-has-children {
        position: relative;
        background-image: url("../../assets/img/arrow-right.png");
        background-repeat: no-repeat;
        background-position: 220px center;
        background-size: 8px 14px;
        &:hover {
          background-image: url("../../assets/img/arrow-right-hover.png");
        }
      }
      .menu-item {
        //width: 300px;
        display: block;
        position: relative;
        a {
          font-family: 'Lora', serif;
          display: block;
          padding: 10px 25px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          background-color: #000;
          & > a {
            color: $yellowColor;
          }
          & > .sub-menu {
            visibility: visible;
            transition: visibility ease 0.1s;
          }
        }
        .sub-menu {
          visibility: hidden;
          transition: visibility ease 0.1s;
          left: 248px;
          top: -25px;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .menu {
    li {
      display: inline;
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }
    & > .menu-item {
      margin: 0 10px;
      font-size: 16px;
    }
  }
}
